import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// Group Debrief
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import GroupDebrief from '../../components/GroupDebrief';

import { roleNames } from '../../constants';

// Actions
import { gameActions } from '../../actions';
import { setupSocket, enterBackend, socketOn } from '../../helpers/sockets';

import Header from '../../components/Header';
import DancefloorChartStrumr from '../../components/DancefloorChartStrumr';
import { DebriefMenu } from './debrief_menu';
// import { faSwatchbook } from '@fortawesome/free-solid-svg-icons';

class DebriefDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: 0,
      squares: false,
      nodeals: false,
      group_modal: null,
    };
    // Bindings
    this.setGroupModal = this.setGroupModal.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      // dispatch(gameActions.fetchGames(match.params.id))
      fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setupSocket(data.io_domain, () => {
            enterBackend({ game_id: match.params.id }, (socketGameData) => {
              console.log({ socketGameData });
            });
            // Sockets!
            socketOn('offer', (m) => {
              dispatch(gameActions.fetchGames(match.params.id));
            }); // socketOn('nodeal', (m) => console.log(m) );
          });
        });
    }
  }

  totalPoints(rindex, finish, band) {
    // Points calculation
    let points_gained = 0;
    let points_lost = 0;
    // STRUMR
    if (rindex === 0) {
      points_gained = finish + band > 4 ? (finish + band - 4) * 10 : 0;
      points_lost = band > 5 ? (band - 5) * 3 : 0;
    }
    // LENNIGANS
    if (rindex === 1) {
      points_gained = band * 10;
      points_lost = finish + band > 8 ? (finish + band - 8) * 2 : 0;
    }
    // Total points for this role
    const total = points_gained - points_lost;
    return total;
  }

  setGroupModal(gindex) {
    const { game } = this.props;
    const group_modal = game.data ? game.data.groups[gindex] : null;
    this.setState({ group_modal });
  }

  render() {
    const { squares, nodeals } = this.state;
    const { game } = this.props;

    if (!game.data) return null;

    const noDealBtns = game.data.groups
      .filter((g) => g.status === 'nodeal')
      .map((g) => {
        const pair_index = game.data.groups.findIndex((gr) => gr._id === g._id);
        // const player0 = g.players.find( pl => pl.rindex === 0 )
        // const player1 = g.players.find( pl => pl.rindex === 1 )
        return (
          <button
            onClick={(e) => this.setGroupModal(pair_index)}
            className='btn btn-outline-primary ml-2 mr-2'>
            {pair_index + 1}
          </button>
        );
      });

    const dances = game.data
      ? game.data.groups.map((dyad, di) => {
          // The dyad name and the player's names
          // If players' names are empty, then use the email
          const player0 = dyad.players.find((pl) => pl.rindex === 0);
          const player1 = dyad.players.find((pl) => pl.rindex === 1);
          const player0FullName = `${player0?.name} ${player0?.lastname}`;
          const player1FullName = `${player1?.name} ${player1?.lastname}`;
          const player0Name = player0FullName || player0?.email;
          const player1Name = player1FullName || player1?.email;
          const dyadName = `${player0Name} vs ${player1Name}`;

          const dance = dyad.items
            .filter((i) => i.status === 'accepted')
            .map((i, ii) => {
              return {
                x: i.band + i.finish,
                y: i.band,
                pair_index: di,
                status:
                  dyad.status === 'nodeal'
                    ? ' No Deal'
                    : dyad.status === 'deal'
                    ? 'Deal'
                    : 'In Progress',
                author: roleNames[i.author_role],
                points: this.totalPoints(i.author_role, i.finish, i.band),
                marker: {
                  enabled: true,
                  radius: 8,
                  symbol: 'circle',
                  fillColor:
                    i.author_role === 0
                      ? 'rgb(97, 46, 160,.7)'
                      : 'rgba(53, 119, 242,.7)',
                },
              };
            });

          return {
            name: dyadName,
            color: 'rgba(53, 119, 242)',
            data: dance,
            showInLegend: false,
            zIndex: 99,
          };
        })
      : [];

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Deals
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 pb-5 text-center bg-white'>
          <DancefloorChartStrumr
            dances={dances}
            squares={squares}
            popup={true}
          />

          <div className='mt-2'>
            <button
              style={{ width: '150px' }}
              className={
                squares
                  ? 'btn btn-primary mr-2'
                  : 'btn btn-outline-primary mr-2'
              }
              onClick={(e) => this.setState({ squares: !squares })}>
              10 Possible
            </button>
            <button
              style={{ width: '150px' }}
              className={
                nodeals
                  ? 'btn btn-primary ml-2'
                  : 'btn btn-outline-primary ml-2'
              }
              onClick={(e) => this.setState({ nodeals: !nodeals })}>
              No Deals
            </button>
          </div>
        </div>

        <Modal
          isOpen={nodeals}
          toggle={(e) => this.setState({ nodeals: !nodeals })}
          centered={true}>
          <ModalHeader>No Deal: Dyads</ModalHeader>
          <ModalBody className='text-center'>
            {noDealBtns.length > 0
              ? 'Click on dyad to review additional feedback.'
              : 'All dyads reached a deal.'}
            <div className='mt-4 mb-3'>
              {noDealBtns.length > 0 && noDealBtns}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='outline-primary'
              onClick={(e) => this.setState({ nodeals: !nodeals })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.group_modal != null ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group_modal: null })}>
          <ModalBody className='text-center'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => this.setState({ group_modal: null })}
            />
            {this.state.group_modal && (
              <GroupDebrief group={this.state.group_modal} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefDeals);
