import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { roleNames } from '../../constants';

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import GroupDebrief from '../../components/GroupDebrief';

// import GroupBtns from '../../components/GroupBtns';

// Setting Highcharts
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

class Satisfaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 0,
      clicked: null,
      group_modal: null,
    };

    // Bindings
    this.setGroupModal = this.setGroupModal.bind(this);
  }

  // componentDidMount(){
  //     if(this.props.match.params.id){
  //         this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
  //     }
  // }

  setGroupModal(gindex) {
    const { game } = this.props;
    const group_modal = game.data ? game.data.groups[gindex] : null;
    this.setState({ group_modal });
  }

  render() {
    const self = this;
    const { game } = this.props;

    let groups = game.data.groups || [];
    // QUICK DEEP CLONE
    groups = JSON.parse(JSON.stringify(groups));

    // Loop the cloned one
    const deals = groups
      .filter((g) => g.status === 'deal')
      .map((g) => {
        const pair_index = groups.findIndex((gr) => gr._id === g._id);
        const player0 = g.players.find((pl) => pl.rindex === 0);
        const player1 = g.players.find((pl) => pl.rindex === 1);

        // The dyad name and the player's names
        // If players' names are empty, then use the email
        const player0FullName = `${player0?.name} ${player0?.lastname}`;
        const player1FullName = `${player1?.name} ${player1?.lastname}`;
        const player0Name = player0FullName || player0?.email;
        const player1Name = player1FullName || player1?.email;
        const dyadName = `${player0Name} vs ${player1Name}`;

        return {
          x: player0.satisfaction,
          y: player1.satisfaction,
          name: dyadName,
          pair_index,
        };
      });
    const noDeals = groups
      .filter((g) => g.status === 'nodeal')
      .map((g) => {
        const pair_index = groups.findIndex((gr) => gr._id === g._id);
        const player0 = g.players.find((pl) => pl.rindex === 0);
        const player1 = g.players.find((pl) => pl.rindex === 1);

        // The dyad name and the player's names
        // If players' names are empty, then use the email
        const player0FullName = `${player0?.name} ${player0?.lastname}`;
        const player1FullName = `${player1?.name} ${player1?.lastname}`;
        const player0Name = player0FullName || player0?.email;
        const player1Name = player1FullName || player1?.email;
        const dyadName = `${player0Name} vs ${player1Name}`;

        return {
          x: player0.satisfaction,
          y: player1.satisfaction,
          name: dyadName,
          pair_index,
        };
      });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Satisfaction
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-12'>
            <div className='bg-white'>
              <div className='col-6 offset-3 mt-2 pt-0'>
                <HighchartsReact
                  containerProps={{ style: { height: '100%' } }}
                  highcharts={Highcharts}
                  options={{
                    title: { text: 'Satisfaction' },
                    chart: { type: 'scatter', height: '500px' },
                    credits: { enabled: false },
                    legend: { enabled: true },
                    xAxis: {
                      title: { text: `${roleNames[0]} Satisfaction` },
                      allowDecimals: false,
                      min: 0,
                      max: 8,
                      // plotBands: [{ color: 'rgba(0,0,0,.1)', width: 2, value: 0 }]
                    },
                    yAxis: {
                      title: { text: `${roleNames[1]} Satisfaction` },
                      allowDecimals: false,
                      min: 0,
                      max: 8,
                      // plotLines: [{ color: 'rgba(0,0,0,.1)', width: 2, value: 0 }]
                    },
                    plotOptions: {
                      scatter: {
                        marker: {
                          radius: 15,
                          // fillColor: 'rgba(0,0,0,.3)',
                          states: {
                            hover: {
                              enabled: true,
                              lineColor: 'rgba(0,0,0,.4)',
                            },
                          },
                        },
                        states: {
                          hover: {
                            marker: {
                              enabled: false,
                            },
                          },
                        },
                        tooltip: {
                          headerFormat: '',
                          pointFormat:
                            '<b>{point.name}</b><br><b>' +
                            roleNames[0] +
                            '</b>: {point.x:,.0f}<br/><b>' +
                            roleNames[1] +
                            '</b>: {point.y:,.0f}',
                        },
                      },
                      series: {
                        cursor: 'pointer',
                        point: {
                          events: {
                            click: function (event) {
                              self.setGroupModal(event.point.pair_index);
                            },
                          },
                        },
                      },
                    },
                    series: [
                      {
                        name: 'Deal',
                        data: deals,
                        zIndex: 1,
                      },
                      {
                        name: 'No Deal',
                        data: noDeals,
                        zIndex: 2,
                      },
                    ],
                  }}
                />
              </div>
            </div>

            <Modal
              isOpen={this.state.group_modal !== null ? true : false}
              centered={true}
              size='xxl'
              toggle={() => this.setState({ group_modal: null })}>
              <ModalBody className='text-center'>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size='2x'
                  className='icon bg-white text-danger'
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                  onClick={() => this.setState({ group_modal: null })}
                />
                {this.state.group_modal && (
                  <GroupDebrief group={this.state.group_modal} />
                )}
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Satisfaction);
